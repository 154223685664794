import React, {Component} from 'react';
import { useSession } from '../../firebase/UserProvider';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { db } from "../../firebase/db";
import UserMetaForm from "../../functions/user-functions/addUserMeta";
import Navbar from "../../components/Navbar";
import { getApp } from "@firebase/app";
import {doc, getDoc, collection, getDocs, setDoc, addDoc, onSnapshot} from "firebase/firestore";
import getCustomClaimRole from "../../functions/user-functions/subsciption";

const app = getApp();



class Account extends Component{
    constructor(props) {
        super(props);
        this.state = {
            display_name:'',
            email: '',
            photoURL:'',
            plans:[]
        }
        this.plans = [];
        this.getPriceID = React.createRef();

        this.componentDidMount = this.componentDidMount(this);
        this.userSubscription = this.userSubscription(this);
    }






    async componentDidMount() {
        console.log(getCustomClaimRole);

        this.userSubscription = async () => {
            let currentUser = getAuth().currentUser;
            const user_id = currentUser.uid;
            console.log(user_id);
            db.collection("customers").doc(user_id).collection("subscriptions")
                //.where("status", "in", ["subscription", "active"])
                .onSnapshot(async (snapshot) => {
                    // In this implementation we only expect one active or trialing subscription to exist.
                    const doc = snapshot.docs[0];
                    if (doc.data() !== undefined) {
                        return (doc.id, " => ", doc.data());
                    } else {


                        {
                            db.collection("plans")
                                .where("active", "==", true)
                                .get()
                                .then(function (querySnapshot) {
                                    querySnapshot.forEach(async function (doc) {
                                        console.log(doc.id, " => ", doc.data());
                                        const priceSnap = await doc.ref.collection("prices").get();
                                        priceSnap.docs.forEach((doc) => {
                                            console.log(doc.id, " => ", doc.data());

                                            return (
                                                <div className="subscription-single" id={doc.price_id}>
                                                    <h4>{doc.name}</h4>
                                                    <h3>{doc.product_price * .01}</h3>
                                                    <button className="green-button"
                                                            onClick={() => this.stripeCheckout()}>Purchase
                                                    </button>
                                                </div>)

                                        });
                                    });
                                });


                        }
                    }
                });
        }

        const auth = getAuth();
        const user = auth.currentUser;
        console.log(user);
        if (user !== null) {
            // The user object has basic properties such as display name, email, etc.
            const displayName = user.displayName;
            this.setState({display_name: displayName});
            const email = user.email;
            this.setState({email: email})
            const photoURL = user.photoURL;
            this.setState({photoURL: photoURL})
            const emailVerified = user.emailVerified;
            const user_id = user.uid;


            // The user's ID, unique to the Firebase project. Do NOT use
            // this value to authenticate with your backend server, if
            // you have one. Use User.getToken() instead.
            const uid = user.uid;

        }
    }


    render(){
        return(
        <div className="pageContainer">
            <Navbar/>
            {this.userSubscription}
            <div className="myAccountContainer">
                <div className="mainUser">
                    <p>Name : {this.state.display_name}</p>
                    <p>Email : {this.state.email}</p>
                    <p>Icon <img src=""></img></p>

                </div>

                <UserMetaForm/>

            </div>

        </div>
    )
    }






}

export default Account;