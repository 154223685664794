import React from 'react';
import firebase from 'firebase/app';
import 'firebase/firestore';
import '@stripe/firestore-stripe-payments';
import { getAuth, signInWithEmailAndPassword, createUserWithEmailAndPassword} from "firebase/auth";



async function getCustomClaimRole() {
    await getAuth().currentUser.getIdToken(true);
    const decodedToken = await getAuth().currentUser.getIdTokenResult();
    return decodedToken.claims.stripeRole;
}

export default getCustomClaimRole;